import React, { Fragment, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useLocation } from "react-router-dom"
import PropTypes from "prop-types"

import iconHeart from "assets/imgs/theme/icons/icon-heart.svg"
import iconCart from "assets/imgs/theme/icons/icon-cart.svg"
import iconUser from "assets/imgs/theme/icons/icon-user.svg"
import Search from "components/ecommerce/search/Search"
import {
  getCategories,
  getCollections,
  getFavouriteItems,
  getLocalCart,
  getQuicklist,
  getUserCart,
  logoutUser,
  setLanguageAction,
  showLoginPopupAction,
} from "store/actions"
import CategoryProduct from "components/ecommerce/filter/CategoryProduct"
import { map } from "lodash"
import CartItemList from "components/elements/cartItemComp2"
import usePrice from "components/hooks/usePrice"
import EmptyCartSvg from "components/Common/emptyCart"
import useLangSwitch from "components/hooks/useLanguageSwicth"
import { useOutsideAlerter } from "components/hooks/useOutSideClick"
import { useWindowSize } from "react-use"
import { MainLogo } from "assets/imgs/logos"
import { useLocalCartPrices } from "components/hooks/useCartAction"
import useCloudinary from "components/hooks/useCloudinaryImage"
import { HiOutlineShoppingCart } from "react-icons/hi"
import LazyLoad from "react-lazyload"
import { TOKEN_PREFIX } from "helpers/api_methods"
import { Button } from "reactstrap"
import i18n from "../../i18n"
import { withTranslation } from "react-i18next"

export const pathsThatNotShowInCheckout = [
  "/cart",
  "/checkout",
  "/checkout/login",
]

const HeaderArabic = ({ toggleClick, headerStyle }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const cateRef = useRef(null)
  const location = useLocation()
  const { width } = useWindowSize()

  const token = localStorage.getItem(TOKEN_PREFIX)

  const {
    cartItems,
    userCart,
    company,
    specialMessages,
    favorites,
    collections,
    localCart,
    user,
    siteLanguage,
  } = useSelector(state => ({
    user: state.Login.user,
    cartItems: state.Cart.cartItems,
    localCart: state.Cart.localCart,
    userCart: state.Cart.userCart,
    company: state?.SiteSettings.siteSettings?.company,
    specialMessages: state.SiteSettings.siteSettings.specialMessages,
    favorites: state.Cart.favorites,
    collections: state.LayoutGroup.collections,
    siteLanguage: state.Layout.siteLanguage,
  }))

  const [isToggled, setToggled] = useState(false)
  const [scroll, setScroll] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState("en")

  const [scrollY, setScrollY] = useState()

  const [outSide, setOutSide] = useState(false)

  useOutsideAlerter(cateRef, setOutSide)

  useEffect(() => {
    const onScroll = e => {
      setScrollY(e.target.documentElement.scrollTop)
    }
    window.addEventListener("scroll", onScroll)

    if (scrollY >= 100 && width > 480) {
      setScroll(true)
    } else {
      setScroll(false)
    }

    return () => window.removeEventListener("scroll", onScroll)
  }, [scrollY])

  const handleToggle = () => setToggled(!isToggled)

  const handleLoginPopup = () => dispatch(showLoginPopupAction())

  const handleLogout = () => {
    dispatch(logoutUser(history))
  }

  useEffect(() => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
    setSelectedLanguage(currentLanguage)
    dispatch(setLanguageAction(currentLanguage))
  }, [])

  const handleLanguageSwitch = lang => {
    window.location.reload()
    i18n.changeLanguage(lang)
    localStorage.setItem("I18N_LANGUAGE", lang)
    dispatch(setLanguageAction(lang))
    setSelectedLanguage(lang)
  }

  useEffect(() => {
    setLanguageAction(selectedLanguage)
  }, [selectedLanguage])

  useEffect(() => {
    dispatch(getUserCart())
    dispatch(getCategories("", "all"))
    dispatch(getFavouriteItems(1, 10))
    // dispatch(getQuicklist(1, 10))
  }, [dispatch])

  useEffect(() => {
    dispatch(getCollections())
  }, [dispatch])

  const { currencyCode } = usePrice()

  const language = useLangSwitch(
    specialMessages?.primaryLang,
    specialMessages?.secondaryLang
  )

  const handleLoginFun = path => {
    if (path) {
      history.push(path)
    }
  }

  useEffect(() => {
    if (outSide === true) {
      setToggled(false)
      setOutSide(false)
    }
  }, [outSide])

  const isHotDeals = collections?.filter(
    i => i?.primaryLang?.title === "Hot Deals"
  )

  const handleCartItem = () => {
    if (cartItems?.length >= 1) {
      return cartItems
    } else if (localCart?.length >= 1) {
      return localCart?.filter(i => i?.quantity !== 0)
    } else {
      return []
    }
  }

  useEffect(() => {
    if (!token) {
      dispatch(getLocalCart())
    }
  }, [])

  const { itemTotal } = useLocalCartPrices()

  const productPaths = ["/products", "/categroies", "/collections"]
  const topMessageNotShow = ["/checkout", "/order-success", "/checkout/login"]

  return (
    <>
      <header className="header-area header-style-1 header-height-2">
        {!topMessageNotShow?.includes(location.pathname) && (
          <>
            {language?.productOffer && (
              <div className="mobile-promotion">
                {language?.productOffer && (
                  <span>{language?.productOffer}</span>
                )}
              </div>
            )}
          </>
        )}
        {pathsThatNotShowInCheckout?.includes(location.pathname) ? (
          <>
            <div className="header-middle py-4 d-none d-lg-block">
              <div className="container">
                <div
                  className="header-wrap"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="header-right mx-4" style={{ width: "0%" }}>
                    <div className="search-style-2 position-relative max-w:600px"></div>
                    <div className="header-action-right">
                      <div className="header-action-2">
                        <div className="header-action-icon-2">
                          {token ? (
                            <>
                              <Link to="/user-account">
                                <span>
                                  {user?.name ? (
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {user?.name?.split(" ")[0]}
                                    </span>
                                  ) : (
                                    <span className="lable pe-2">حساب</span>
                                  )}
                                </span>
                              </Link>
                              <Link to="/user-account">
                                <span>
                                  <img
                                    title="Idealsply-user"
                                    loading="eager"
                                    className="svgInject"
                                    alt="Idealsply-user"
                                    src={iconUser}
                                  />
                                </span>
                              </Link>
                            </>
                          ) : (
                            <>
                              <Link to="#!" onClick={handleLoginPopup}>
                                <span>
                                  <span className="lable pe-2">
                                    تسجيل الدخول
                                  </span>
                                </span>
                              </Link>
                              <Link to="#!" onClick={handleLoginPopup}>
                                <span>
                                  <img
                                    className="svgInject"
                                    alt="Idealsply-user"
                                    src={iconUser}
                                    title="Idealsply-user"
                                    loading="eager"
                                  />
                                </span>
                              </Link>
                            </>
                          )}
                          {token ? (
                            <div className="cart-dropdown-wrap cart-dropdown-wrap-ar cart-dropdown-hm2 account-dropdown">
                              <ul>
                                <li className="justify-content-end">
                                  <Link to="/user-account">
                                    <span>
                                      حسابي
                                      <i className="fi fi-rs-user ml-10"></i>
                                    </span>
                                  </Link>
                                </li>
                                <li className="justify-content-end">
                                  <Link
                                    to={{
                                      pathname: "/user-account",
                                      state: { index: 2 },
                                    }}
                                  >
                                    <span>
                                      تتبع الطلب
                                      <i className="fi fi-rs-location-alt ml-10"></i>
                                    </span>
                                  </Link>
                                </li>
                                <li className="justify-content-end">
                                  <Link
                                    to={{
                                      pathname: "/user-account",
                                      state: { index: 4 },
                                    }}
                                  >
                                    <span>
                                      عنواني
                                      <i className="fi fi-rs-label ml-10"></i>
                                    </span>
                                  </Link>
                                </li>
                                <li className="justify-content-end">
                                  <Link
                                    to={"#!"}
                                    onClick={() => handleLoginFun("/wishlist")}
                                  >
                                    <span>
                                      قائمة امنياتي
                                      <i className="fi fi-rs-heart ml-10"></i>
                                    </span>
                                  </Link>
                                </li>

                                <li className="justify-content-end">
                                  <Link to="#!" onClick={handleLogout}>
                                    <span>
                                      خروج
                                      <i className="fi fi-rs-sign-out ml-10"></i>
                                    </span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="header-action-icon-2 cart-ar">
                          <Link
                            to={"#!"}
                            onClick={() => handleLoginFun("/cart")}
                          >
                            <span className="lable pe-2">سلة التسوق</span>
                          </Link>

                          <Link
                            to={"#!"}
                            onClick={() => handleLoginFun("/cart")}
                          >
                            <div className="mini-cart-icon">
                              <img
                                alt="Idealsply-cart"
                                title="Idealsply-cart"
                                loading="eager"
                                src={iconCart}
                              />
                              {handleCartItem()?.length >= 1 ? (
                                <span className="pro-count blue">
                                  {handleCartItem()?.length}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </Link>

                          <div className="cart-dropdown-wrap cart-dropdown-hm2">
                            {handleCartItem()?.length >= 1 ? (
                              <>
                                <ul>
                                  {map(handleCartItem(), (item, key) => (
                                    <Fragment key={key}>
                                      <CartItemList item={item} />
                                    </Fragment>
                                  ))}
                                </ul>

                                <div className="shopping-cart-footer">
                                  <div className="shopping-cart-total">
                                    {userCart?.shippingPrice ? (
                                      <p className="d-flex justify-content-between">
                                        <span className="">
                                          {currencyCode}{" "}
                                          {userCart?.shippingPrice ?? 0}
                                        </span>
                                        السفينة
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    {userCart?.couponDiscount ? (
                                      <p className="d-flex justify-content-between">
                                        <span className="">
                                          - {currencyCode}{" "}
                                          {userCart?.couponDiscount}
                                        </span>
                                        كوبون التخفيض
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    {userCart?.coupon ? (
                                      <p className="d-flex justify-content-between">
                                        <span className="">
                                          {userCart?.coupon}
                                        </span>
                                        كوبون
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    <h4 className="d-flex justify-content-between">
                                      <span
                                        className="text-black"
                                        style={{ float: "left" }}
                                      >
                                        {currencyCode}{" "}
                                        {userCart?.totalPrice
                                          ? userCart?.totalPrice
                                          : itemTotal}
                                      </span>
                                      اجمالي
                                    </h4>
                                  </div>
                                  <div className="shopping-cart-button">
                                    <Link
                                      onClick={() => handleLoginFun("/cart")}
                                      to={"#!"}
                                      className="bg-green"
                                      style={{ border: "none" }}
                                    >
                                      مشاهدة كارت
                                    </Link>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <EmptyCartSvg style={{ height: "200px" }} />
                            )}
                          </div>
                        </div>
                        <div className="header-action-icon-2 ">
                          <Link
                            to={"#!"}
                            onClick={() => handleLoginFun("/wishlist")}
                          >
                            <span className="lable pe-2">قائمة الرغبات</span>
                          </Link>
                          <Link
                            to={"#!"}
                            onClick={() => handleLoginFun("/wishlist")}
                          >
                            <span>
                              <img
                                className="svgInject"
                                alt="Idealsply-favorites"
                                src={iconHeart}
                                title="Idealsply-favorites"
                                loading="eager"
                              />
                              {favorites?.total >= 1 ? (
                                <span className="pro-count blue">
                                  {favorites?.total}
                                </span>
                              ) : (
                                ""
                              )}
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="logo logo-width-2 d-flex align-items-center">
                    <div>
                      <Button
                        className="btn-sm"
                        style={{ lineHeight: 1.5 }}
                        onClick={() =>
                          handleLanguageSwitch(
                            selectedLanguage === "en" ? "ar" : "en"
                          )
                        }
                      >
                        <strong>
                          &nbsp;
                          {selectedLanguage === "en" ? "العربية" : "English"}
                        </strong>
                      </Button>
                    </div>
                    <Link to="/">
                      <LazyLoad height={80}>
                        <img
                          src={
                            company?.logo[0]?.url
                              ? useCloudinary(company?.logo[0]?.url, "w_300")
                              : MainLogo
                          }
                          height="50px"
                          width={"150px"}
                          alt="Idealsply-logo"
                          className="fit-contain mx-4"
                          title="Idealsply-logo"
                          loading="eager"
                        />
                      </LazyLoad>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="header-top header-top-ptb-1 d-none d-lg-block">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-xl-3 col-lg-4">
                    <div className="header-info header-info-right">
                      <ul>
                        <li>
                          <strong className="text-brand pe-2">
                            &nbsp;{company?.contactDetails?.mobile}
                          </strong>
                          : ترجو مساعدة؟ التواصل معنا
                          {/* Need help ?&nbsp; Call Us&nbsp;:{" "} */}
                        </li>
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleLanguageSwitch(
                              selectedLanguage === "en" ? "ar" : "en"
                            )
                          }
                        >
                          <Button
                            className="btn-sm"
                            style={{ lineHeight: 1.5 }}
                          >
                            <strong>
                              &nbsp;
                              {selectedLanguage === "en"
                                ? "العربية"
                                : "English"}
                            </strong>
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-4">
                    <div className="text-center">
                      <div id="news-flash" className="d-inline-block">
                        <ul>
                          {language?.bannerOffer && (
                            <li>
                              {language?.bannerOffer}&nbsp;
                              {/* <Link to="/shop-grid-right">
                                <span> View details</span>
                              </Link> */}
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4">
                    <div className="header-info">
                      <ul>
                        <li>
                          <Link to={"/categroies"}>
                            <span>فئات</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: "/user-account",
                              state: { index: 2 },
                            }}
                          >
                            <span>تتبع الطلب</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"#!"}
                            onClick={() => handleLoginFun("/wishlist")}
                          >
                            <span>قائمة الرغبات</span>
                          </Link>
                        </li>
                        <li>
                          {token ? (
                            <Link
                              to={{
                                pathname: "/user-account",
                                state: { index: 4 },
                              }}
                            >
                              <span>حسابي</span>
                            </Link>
                          ) : (
                            <span
                              onClick={handleLoginPopup}
                              className="cursor-pointer"
                            >
                              تسجيل الدخول
                              <i
                                className="fi fi-rs-user"
                                style={{ paddingLeft: "10px" }}
                              ></i>
                            </span>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="header-middle header-middle-ptb-1 d-none d-lg-block">
              <div className="container">
                <div className="header-wrap">
                  <div className="header-right mx-4">
                    <div className="header-action-right">
                      <div className="header-action-2">
                        <div className="header-action-icon-2">
                          {token ? (
                            <>
                              <Link to="/user-account">
                                <span>
                                  {user?.name ? (
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {user?.name?.split(" ")[0]}
                                    </span>
                                  ) : (
                                    <span className="lable pe-2">حساب</span>
                                  )}
                                </span>
                              </Link>
                              <Link to="/user-account">
                                <span>
                                  <img
                                    title="Idealsply-user"
                                    loading="eager"
                                    className="svgInject"
                                    alt="Idealsply-user"
                                    src={iconUser}
                                  />
                                </span>
                              </Link>
                            </>
                          ) : (
                            <>
                              <Link to="#!" onClick={handleLoginPopup}>
                                <span>
                                  <span className="lable pe-2">
                                    تسجيل الدخول
                                  </span>
                                </span>
                              </Link>
                              <Link to="#!" onClick={handleLoginPopup}>
                                <span>
                                  <img
                                    className="svgInject"
                                    alt="Idealsply-user"
                                    src={iconUser}
                                    title="Idealsply-user"
                                    loading="eager"
                                  />
                                </span>
                              </Link>
                            </>
                          )}
                          {token ? (
                            <div className="cart-dropdown-wrap cart-dropdown-wrap-ar cart-dropdown-hm2 account-dropdown">
                              <ul>
                                <li className="justify-content-end">
                                  <Link to="/user-account">
                                    <span>
                                      حسابي
                                      <i className="fi fi-rs-user ml-10"></i>
                                    </span>
                                  </Link>
                                </li>
                                <li className="justify-content-end">
                                  <Link
                                    to={{
                                      pathname: "/user-account",
                                      state: { index: 2 },
                                    }}
                                  >
                                    <span>
                                      تتبع الطلب
                                      <i className="fi fi-rs-location-alt ml-10"></i>
                                    </span>
                                  </Link>
                                </li>
                                <li className="justify-content-end">
                                  <Link
                                    to={{
                                      pathname: "/user-account",
                                      state: { index: 4 },
                                    }}
                                  >
                                    <span>
                                      عنواني
                                      <i className="fi fi-rs-label ml-10"></i>
                                    </span>
                                  </Link>
                                </li>
                                <li className="justify-content-end">
                                  <Link
                                    to={"#!"}
                                    onClick={() => handleLoginFun("/wishlist")}
                                  >
                                    <span>
                                      قائمة امنياتي
                                      <i className="fi fi-rs-heart ml-10"></i>
                                    </span>
                                  </Link>
                                </li>

                                <li className="justify-content-end">
                                  <Link to="#!" onClick={handleLogout}>
                                    <span>
                                      خروج
                                      <i className="fi fi-rs-sign-out ml-10"></i>
                                    </span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="header-action-icon-2 cart-ar">
                          <Link
                            to={"#!"}
                            onClick={() => handleLoginFun("/cart")}
                          >
                            <span className="lable pe-2">سلة التسوق</span>
                          </Link>
                          <Link
                            to={"#!"}
                            onClick={() => handleLoginFun("/cart")}
                          >
                            <div className="mini-cart-icon">
                              <img
                                alt="Idealsply-cart"
                                title="Idealsply-cart"
                                loading="eager"
                                src={iconCart}
                              />
                              {handleCartItem()?.length >= 1 ? (
                                <span className="pro-count blue">
                                  {handleCartItem()?.length}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </Link>
                          <div className="cart-dropdown-wrap cart-dropdown-hm2">
                            {handleCartItem()?.length >= 1 ? (
                              <>
                                <ul>
                                  {map(handleCartItem(), (item, key) => (
                                    <Fragment key={key}>
                                      <CartItemList item={item} />
                                    </Fragment>
                                  ))}
                                </ul>

                                <div className="shopping-cart-footer">
                                  <div className="shopping-cart-total">
                                    {userCart?.shippingPrice ? (
                                      <p className="d-flex justify-content-between">
                                        <span className="">
                                          {currencyCode}{" "}
                                          {userCart?.shippingPrice ?? 0}
                                        </span>
                                        السفينة
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    {userCart?.couponDiscount ? (
                                      <p className="d-flex justify-content-between">
                                        <span className="">
                                          - {currencyCode}{" "}
                                          {userCart?.couponDiscount}
                                        </span>
                                        كوبون التخفيض
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    <h4 className="d-flex justify-content-between">
                                      <span
                                        className="text-success"
                                        style={{ float: "left" }}
                                      >
                                        {currencyCode}{" "}
                                        {userCart?.totalPrice
                                          ? userCart?.totalPrice
                                          : itemTotal}
                                      </span>
                                      اجمالي
                                    </h4>
                                  </div>
                                  <div className="shopping-cart-button">
                                    <Link
                                      onClick={() => handleLoginFun("/cart")}
                                      to={"#!"}
                                    >
                                      مشاهدة كارت
                                    </Link>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <EmptyCartSvg style={{ height: "200px" }} />
                            )}
                          </div>
                        </div>
                        <div className="header-action-icon-2">
                          <Link
                            to={"#!"}
                            onClick={() => handleLoginFun("/wishlist")}
                          >
                            <span className="lable pe-2">قائمة الرغبات</span>
                          </Link>
                          <Link
                            to={"#!"}
                            onClick={() => handleLoginFun("/wishlist")}
                          >
                            <span>
                              <img
                                className="svgInject"
                                alt="Idealsply-favorites"
                                src={iconHeart}
                                title="Idealsply-favorites"
                                loading="eager"
                              />
                              {favorites?.total >= 1 ? (
                                <span className="pro-count blue">
                                  {favorites?.total}
                                </span>
                              ) : (
                                ""
                              )}
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="search-style-2 search-3 position-relative max-w:600px">
                      <Search />
                    </div>
                  </div>
                  <div className="logo logo-width-2">
                    <Link to="/">
                      <LazyLoad height={80}>
                        <img
                          src={
                            company?.logo[0]?.url
                              ? useCloudinary(company?.logo[0]?.url, "w_300")
                              : MainLogo
                          }
                          height="80px"
                          width={"160px"}
                          alt="Idealsply-logo"
                          className="fit-contain mx-4"
                          title="Idealsply-logo"
                          loading="eager"
                        />
                      </LazyLoad>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* //mobile */}
        <div
          className={
            scroll && productPaths?.includes(location.pathname)
              ? "header-bottom header-bottom-bg-color sticky-bar stick"
              : "header-bottom header-bottom-bg-color sticky-bar"
          }
        >
          {width <= 480 &&
          scroll === true &&
          productPaths?.includes(location.pathname) ? (
            <div
              className={`search-style-2 position-relative max-w:600px myMobileSearch ${
                scroll === true ? "scroll" : ""
              }`}
            >
              <Search isMobile={true} />
            </div>
          ) : (
            <div className="container">
              <div className="header-wrap header-space-between header-space-between-ar position-relative">
                <div
                  className="logo logo-width d-block d-lg-none d-flex align-items-center"
                  style={{ padding: width > 768 && "15px 0" }}
                >
                  <div className="header-action-icon d-block d-lg-none ">
                    <div
                      className="burger-icon burger-icon-white"
                      onClick={toggleClick}
                    >
                      <span className="burger-icon-top"></span>
                      <span className="burger-icon-mid"></span>
                      <span className="burger-icon-bottom"></span>
                    </div>
                  </div>
                  <Link
                    to="/"
                    className="mx-1"
                    style={{ height: "40px", width: "110px" }}
                  >
                    <LazyLoad height={200}>
                      <img
                        src={company?.logo[0]?.url ?? MainLogo}
                        alt="Idealsply-logo"
                        height="100%"
                        width={"100%"}
                        className="fit-cover"
                        title="Idealsply-logo"
                        loading="eager"
                      />
                    </LazyLoad>
                  </Link>
                  <Button
                    onClick={() =>
                      handleLanguageSwitch(
                        selectedLanguage === "en" ? "ar" : "en"
                      )
                    }
                    className="btn-sm"
                    style={{ lineHeight: 1.5 }}
                  >
                    <strong>
                      &nbsp;
                      {selectedLanguage === "en" ? "العربية" : "English"}
                    </strong>
                  </Button>
                </div>

                {!pathsThatNotShowInCheckout?.includes(location.pathname) && (
                  <div className="header-nav d-none d-lg-flex">
                    {width > 992 && scroll === true && (
                      <div
                        className="header-action-icon-2 d-flex align-items-center"
                        style={{ marginRight: "720px" }}
                      >
                        <Link
                          className="position-relative"
                          to={"#!"}
                          onClick={() => handleLoginFun("/wishlist")}
                        >
                          <span>
                            <img
                              className="svgInject"
                              alt="Idealsply-logo"
                              src={iconHeart}
                              title="Idealsply-logo"
                              loading="eager"
                            />
                          </span>
                        </Link>
                        <Link
                          to={"#!"}
                          className="position-relative mx-2"
                          onClick={() => handleLoginFun("/cart")}
                        >
                          <div className="mini-cart-icon">
                            <img
                              alt="Idealsply-logo"
                              title="Idealsply-logo"
                              loading="eager"
                              src={iconCart}
                            />
                            {handleCartItem()?.length >= 1 ? (
                              <span
                                className="pro-count text-white navIcon2 bg-darkGreen px-1"
                                style={{ borderRadius: "50%" }}
                              >
                                {handleCartItem()?.length}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </Link>
                        {token && (
                          <Link to="/user-account">
                            <span>
                              <img
                                alt="Idealsply-user"
                                className="svgInject"
                                src={iconUser}
                                title="Idealsply-user"
                                loading="eager"
                              />
                            </span>
                          </Link>
                        )}
                      </div>
                    )}
                    <div
                      className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block  font-heading
                
                "
                      // style={{ width: "766px" }}
                    >
                      <nav>
                        <ul>
                          {/* {isHotDeals && (
                            <li className="hot-deals ">
                              <Link
                                to={isHotDeals ? `/collections/Hot-Deals` : "/"}
                                className="d-flex align-items-center justify-content-center"
                              >
                                <AiOutlineFire
                                  size={"1rem"}
                                  className="secondary-color"
                                />
                                Hot Deals
                              </Link>
                            </li>
                          )} */}
                          <li>
                            <Link to="/collections">مجموعات المنتجات</Link>
                          </li>
                          <li>
                            <Link to="/categroies">فئات</Link>
                          </li>

                          <li>
                            <Link
                              to={{
                                pathname: "/products",
                                query: {
                                  prod: "all",
                                },
                              }}
                            >
                              جميع المنتجات
                            </Link>
                          </li>
                          {/* <li>
                            <Link to="/pages">Pages</Link>
                          </li>
                          <li>
                            <Link to="/blogs">Blogs</Link>
                          </li> */}
                          {/* 
                      <li>
                        <Link to="/about">About</Link>
                      </li> */}
                        </ul>
                      </nav>
                    </div>
                    <div className="main-categori-wrap main-categori-wrap-ar d-none d-lg-block">
                      <a
                        className="categories-button-active"
                        onClick={handleToggle}
                      >
                        <span className="fi-rs-apps"></span>
                        <span> تصفح جميع الفئات</span>
                        <i className="fi-rs-angle-down"></i>
                      </a>

                      <div
                        className={
                          isToggled
                            ? `categories-dropdown-wrap categories-dropdown-active-large ${
                                selectedLanguage === "ar" &&
                                "categories-dropdown-active-large-ar"
                              } font-heading open `
                            : `categories-dropdown-wrap categories-dropdown-active-large ${
                                selectedLanguage === "ar" &&
                                "categories-dropdown-active-large-ar"
                              } font-heading`
                        }
                      >
                        <div
                          className="d-flex categori-dropdown-inner"
                          style={{
                            justifyContent: selectedLanguage === "ar" && "end",
                          }}
                          ref={cateRef}
                        >
                          <CategoryProduct
                            className="list_cat"
                            showAll={true}
                            showCount={false}
                            handleToggle={handleToggle}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="header-action-icon-2 d-block d-lg-none">
                  &nbsp;
                </div>

                <div className="header-action-right d-block d-lg-none">
                  <div className="header-action-2">
                    {!token ? (
                      <div className="header-action-icon-2">
                        <Link
                          className="m-0 d-flex align-items-center justify-content-center"
                          to="#!"
                          onClick={handleLoginPopup}
                        >
                          <span className="primary-color">Login</span>
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="header-action-icon-2">
                      <Link
                        to={"#!"}
                        onClick={() => handleLoginFun("/wishlist")}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="mini-cart-icon">
                          <img
                            alt="Idealsply-favorites"
                            title="Idealsply-favorites"
                            loading="eager"
                            src={iconHeart}
                          />
                          {favorites?.total >= 1 && (
                            <span className="pro-count white">
                              {favorites?.total}
                            </span>
                          )}
                        </span>
                      </Link>
                      <Link
                        to={"#!"}
                        onClick={() => handleLoginFun("/cart")}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="mini-cart-icon">
                          <HiOutlineShoppingCart style={{ fontSize: "22px" }} />
                          {handleCartItem()?.length >= 1 && (
                            <span
                              className="pro-count text-white navIcon2 bg-darkGreen px-0"
                              style={{ borderRadius: "50%" }}
                            >
                              {handleCartItem()?.length}
                            </span>
                          )}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {scroll === true ? (
            <></>
          ) : (
            <div
              className={`search-style-2 position-relative max-w:600px myMobileSearch ${
                scroll === true ? "scroll" : ""
              }`}
            >
              <Search isMobile={true} />
            </div>
          )}
        </div>
      </header>
    </>
  )
}

export default withTranslation()(HeaderArabic)

HeaderArabic.propTypes = {
  totalCartItems: PropTypes.any,
  toggleClick: PropTypes.any,
  totalWishlistItems: PropTypes.any,
  headerStyle: PropTypes.string,
}
