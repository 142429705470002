export const useArrowToSpace = str => {
  return str?.length >= 1 && str?.replace(/-/g, " ")
}

// import { useLangSwitchquery } from "./useLanguageSwicth"

// export const useArrowToSpace = str => {
//   const language = useLangSwitchquery();

//   return language === 'primary' ? str?.replace(/-/g, " ") : str?.replace(/ /g, '-');
// };
