import React from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import useLangSwitch, {
  useLangSwitchquery,
} from "components/hooks/useLanguageSwicth"
import usePrice from "components/hooks/usePrice"
import { FaHeart, FaRegHeart } from "react-icons/fa"
import moment from "moment"
import getDifferenceInDays from "components/hooks/useGetDifferenceInDays"
import { defualtProd } from "assets/imgs/logos"
import useWishlistAction from "components/hooks/useWishlistAction"
import storage from "util/localStorage"
import { useWindowSize } from "react-use"
import useCloudinary from "components/hooks/useCloudinaryImage"
import { useRemoveSpace } from "components/hooks/useRemoveSpace"
import LazyLoad from "react-lazyload"

const ProductCardOne = ({ product, className }) => {
  const dispatch = useDispatch()

  const { freeShippingThreshold } = useSelector(state => ({
    freeShippingThreshold:
      state.SiteSettings.siteSettings.shipping?.freeShippingThreshold,
  }))

  const user_favourite =
    storage.get("user_favourite") !== null && storage.get("user_favourite")

  const handleWishlist = productId => {
    useWishlistAction(dispatch, productId)
  }

  const language = useLangSwitch(product?.primaryLang, product?.secondaryLang)
  const switchLanguage = useLangSwitchquery()

  const { currencyCode, oldPrice, price, stock, currentVarient } =
    usePrice(product)

  const createdAt = moment(product?.createdAt).format("MM/DD/YYYY")
  const today = moment(new Date()).format("MM/DD/YYYY")

  const diffInDays = getDifferenceInDays(createdAt, today)

  const diff = Math.abs(oldPrice - price)

  const discountPercentage =
    (oldPrice ?? 0) > price ? Math.floor((diff / oldPrice) * 100) : ""

  const { width } = useWindowSize()

  return (
    <div className={`product-cart-wrap shoplist-child mb-30 ${className}`}>
      <div className="product-img-action-wrap shoplist flex-1 position-relative">
        <div className="product-img product-img-zoom">
          <Link to={`/products/${useRemoveSpace(product?.primaryLang?.name)}`}>
            <img
              className="default-img"
              src={`${
                useCloudinary(product.images[0]?.url, "w_500") ?? defualtProd
              }`}
              alt="Idealsply-defualt-img"
              title="Idealsply-defualt-img"
              loading="eager"
            />
            {width >= 480 ? (
              <LazyLoad height={200}>
                <img
                  className="hover-img"
                  src={
                    useCloudinary(
                      product.images[1]?.url
                        ? product.images[1]?.url
                        : product.images[0]?.url,
                      "w_500"
                    ) ?? defualtProd
                  }
                  alt="Idealsply-product"
                  title="Idealsply-produt"
                  loading="eager"
                />
              </LazyLoad>
            ) : (
              ""
            )}
          </Link>
        </div>

        <div className="product-action-1">
          <a
            aria-label="Add To Wishlist"
            className="action-btn hover-up"
            onClick={e => handleWishlist(product?._id)}
          >
            {user_favourite?.length >= 1 &&
            user_favourite?.includes(product?._id) ? (
              <FaHeart className="text-danger" style={{ marginTop: "-6px" }} />
            ) : (
              <FaRegHeart
                className="text-black"
                style={{ marginTop: "-6px" }}
              />
            )}
          </a>
        </div>

        <div
          className="product-badges product-badges-position 
          product-badges-mrg"
        >
          {stock <= 0 ? (
            <span className="text-white best curvy" style={{ zIndex: 5 }}>
              ! Out of stock
            </span>
          ) : (
            <>
              {product?.isFeatured && <span className="hot curvy">Hot</span>}
              {diffInDays >= 1 && diffInDays <= 2 && (
                <span className="new curvy">New</span>
              )}
            </>
          )}
        </div>
      </div>
      <div className="product-content-wrap pt-1 w-100">
        <h2
          className={`mb-0 mt-1 ${
            switchLanguage === "Secondary" && "text-end"
          }`}
        >
          <Link
            to={`/products/${useRemoveSpace(product?.primaryLang?.name)}`}
            className="font-normal"
            style={{ fontWeight: "normal" }}
          >
            <span>
              {language?.name?.slice(0, 86)} &nbsp;{" "}
              {currentVarient && (
                <span style={{ whiteSpace: "nowrap" }}>({currentVarient})</span>
              )}{" "}
            </span>
          </Link>
        </h2>

        {switchLanguage === "Primary" ? (
          <div className="product-card-bottom mt-0">
            <div className="product-price">
              {oldPrice && (
                <>
                  <span
                    className="old-price font-normal m-0"
                    style={{ fontWeight: "500" }}
                  >
                    {currencyCode}
                    {oldPrice}
                  </span>
                  &nbsp;
                </>
              )}
              <span
                className="font-head text-black"
                style={{ margin: "0 5px 0 5px", fontWeight: "500" }}
              >
                {currencyCode}
                {price}&nbsp;
              </span>
              {(product?.discountPercentage > 0 || discountPercentage > 0) && (
                <span className="price-per">
                  {product?.discountPercentage ?? discountPercentage}% off
                </span>
              )}
            </div>
          </div>
        ) : (
          <div
            className="product-card-bottom mt-0"
            style={{ justifyContent: "end" }}
          >
            <div className="product-price">
              <span
                className="font-head text-black"
                style={{ fontWeight: "500" }}
              >
                {currencyCode}
                {price}&nbsp;
              </span>
              {(product?.discountPercentage > 0 || discountPercentage > 0) && (
                <span className="price-per pe-2">
                  {product?.discountPercentage ?? discountPercentage}% off
                </span>
              )}
              {oldPrice && (
                <>
                  <span
                    className="old-price font-normal m-0"
                    style={{ fontWeight: "500" }}
                  >
                    {currencyCode}
                    {oldPrice}
                  </span>
                  &nbsp;
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const mapDispatchToProps = {}

export default connect(null, mapDispatchToProps)(ProductCardOne)

ProductCardOne.propTypes = {
  product: PropTypes.any,
  className: PropTypes.string,
}
