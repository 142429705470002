import Layout from "components/layout"
import { TOKEN_PREFIX } from "helpers/api_methods"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { showLoginPopupAction } from "store/actions"

const RegistrationLogin = () => {
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()
  const token = localStorage.getItem(TOKEN_PREFIX)

  const orderId = params?.id

  const handleLoginPopup = () => dispatch(showLoginPopupAction())

  useEffect(() => {
    if (!token) {
      handleLoginPopup()
    } else {
      history.push(`/course/registration/${orderId}`)
    }
  }, [token])

  return (
    <>
      <Layout parent="Home" sub="Course" subChild="Registration">
        <main className="main ">
          <div className="container">
            <div className="deal custom-border align-items-center flex-col d-flex justify-content-center sm:p-0">
              {!token && (
                <div className="text-center">
                  <h5
                    className="m-auto w-100"
                    style={{ maxWidth: "30%", lineHeight: "30px" }}
                  >
                    To access the course registration form, kindly proceed with
                    the login process.
                  </h5>
                  <button
                    onClick={handleLoginPopup}
                    type="button"
                    className="btn btn-fill-out btn-block btn-md w-25 sm:w-50 ml-auto mx-3 mt-30"
                  >
                    Login Now
                  </button>
                </div>
              )}
            </div>
          </div>
        </main>
      </Layout>
    </>
  )
}

export default RegistrationLogin

RegistrationLogin.propTypes = {}
