import ThreeDotsLoading from "components/Common/loaders/ThreeDotLoading"
import SearchListVertical from "components/elements/SearchListVertical"
import { useOutsideAlerter } from "components/hooks/useOutSideClick"
import { debounce, map } from "lodash"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { getProducts, getProductsByCategory } from "store/actions"
import PropTypes from "prop-types"
import { useLangSwitchquery } from "components/hooks/useLanguageSwicth"

const Search = ({ isMobile, isSingle }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const searchRef = useRef(null)
  const location = useLocation()
  const language = useLangSwitchquery()

  const { allCategories, loading, products } = useSelector(state => ({
    allCategories: state.Classification.categories,
    loading: state.Products.loading,
    products: state.Products.products,
  }))

  const [searchTerm, setSearchTerm] = useState("")
  const [finalInput, setFinalInput] = useState("")
  const [selectedCat, setSelectedCat] = useState("")

  const [showList, setsHowList] = useState(false)

  const [outSide, setOutSide] = useState(false)

  useOutsideAlerter(searchRef, setOutSide)

  useEffect(() => {
    const options = {
      searchText: finalInput,
      page: "",
      custom: "all",
      sort: "",
      limit: "",
    }

    const CatOptions = {
      ...options,
      custom: selectedCat,
    }

    if (selectedCat && selectedCat !== "All") {
      dispatch(getProductsByCategory(CatOptions))
    } else {
      if (finalInput) {
        dispatch(getProducts(options))
      }
    }
  }, [finalInput, selectedCat])

  const handleInput = e => {
    const finalprodName = products?.products[0]?.primaryLang?.name

    const removeSpace = finalprodName?.replace(/ /g, "-")

    if (e.key === "Enter") {
      e.preventDefault()
      history.push(`/products/${removeSpace}`)
    }
  }

  const printValue = debounce(value => setFinalInput(value), 500)

  const handleChangeSearch = value => {
    printValue(value)
    setSearchTerm(value)
    setsHowList(true)
  }

  useEffect(() => {
    if (outSide === true) {
      setsHowList(false)
      setOutSide(false)
    }
  }, [outSide])

  useEffect(() => {
    if (finalInput?.length >= 1) {
      history.push({
        pathname: location?.pathname,
        query: {
          ...location.query,
          isSearch: true,
          search: finalInput,
        },
      })
    } else {
      // history.push({
      //   pathname: location?.pathname,
      //   query: {
      //     ...location.query,
      //     isSearch: false,
      //     search: finalInput,
      //   },
      // })
    }
  }, [finalInput])

  const [hideList, setHideList] = useState(false)

  useEffect(() => {
    if (
      location?.pathname === "/products" ||
      location?.pathname === "/collection" ||
      location?.pathname === "/category"
    ) {
      setHideList(true)
    } else {
      setHideList(false)
    }
  }, [location?.pathname])

  return (
    <>
      <form onClick={() => isMobile && history.push("/search")}>
        {!isMobile && (
          <select
            className="select-active"
            onChange={e => setSelectedCat(e.target.value)}
            style={{
              maxWidth: "fit-content",
              width: selectedCat?.length
                ? `${selectedCat?.length * 10}px`
                : "140px",
              paddingRight: 0,
            }}
          >
            <option>{language === "Primary" ? "All" : "الجميع"}</option>
            {map(allCategories, (cat, key) => (
              <option key={key}>
                {language === "Primary"
                  ? cat.primaryLang.name
                  : cat.secondaryLang.name}
              </option>
            ))}
          </select>
        )}
        <input
          value={searchTerm}
          onKeyDown={handleInput}
          onChange={e => handleChangeSearch(e.target.value)}
          type="text"
          placeholder={
            language === "Primary" ? "Search for products" : "بحث عن المنتجات"
          }
          style={{
            marginLeft: 0,
            textAlign: language === "Secondary" && "end",
            paddingRight: language === "Secondary" && "50px",
          }}
          autoFocus={isSingle && "autofocus"}
          className={language === "Secondary" && "search-ar-sm"}
        />
      </form>
      {hideList === false && (
        <>
          {searchTerm && showList && (
            <div
              className={`zIndex-full position-absolute bg-white boxshadow-4 border-radius-15 right-0 col-md-4-5 col-lg-4-5 ${
                isMobile ? "myMobileSearchList" : ""
              }`}
              ref={searchRef}
            >
              {loading ? (
                <ThreeDotsLoading />
              ) : (
                <>
                  {map(products?.products?.slice(0, 5), (product, key) => (
                    <SearchListVertical
                      setsHowList={setsHowList}
                      product={product}
                      key={key}
                    />
                  ))}
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  )
}

export default Search
Search.propTypes = {
  isMobile: PropTypes.bool,
  isSingle: PropTypes.bool,
}
