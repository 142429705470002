import React from "react"
import LayoutArabic from "./LayoutArabic"
import PropTypes from "prop-types"
import LayoutEnglish from "./LayoutEnglish"

function Layout({
  children,
  parent,
  sub,
  subChild,
  noBreadcrumb,
  headerStyle,
}) {
  const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
  return (
    <>
      {currentLanguage === "ar" ? (
        <LayoutArabic
          parent={parent}
          sub={sub}
          // eslint-disable-next-line react/no-children-prop
          children={children}
          subChild={subChild}
          noBreadcrumb={noBreadcrumb}
          headerStyle={headerStyle}
        />
      ) : (
        <LayoutEnglish
          parent={parent}
          sub={sub}
          // eslint-disable-next-line react/no-children-prop
          children={children}
          subChild={subChild}
          noBreadcrumb={noBreadcrumb}
          headerStyle={headerStyle}
        />
      )}
    </>
  )
}

export default Layout

Layout.propTypes = {
  children: PropTypes.any,
  parent: PropTypes.any,
  sub: PropTypes.any,
  subChild: PropTypes.any,
  noBreadcrumb: PropTypes.any,
  headerStyle: PropTypes.any,
}
