import React from "react"
import PropTypes from "prop-types"

import empty from "assets/imgs/shop/cart-empty.gif"
import { Link } from "react-router-dom"
import LazyLoad from "react-lazyload"
import { useLangSwitchquery } from "components/hooks/useLanguageSwicth"

function EmptyCartSvg({ style }) {
  const language = useLangSwitchquery()
  return (
    <>
      {language === "Primary" ? (
        <>
          <div
            className="d-flex align-items-center flex-revers-col emptyCart"
            style={style}
          >
            <div>
              {style?.isDes ? (
                <>
                  <h4 className="text-brand cartempty">Your cart is empty!</h4>
                  <p className="text-black cartemptySub">
                    “Explore our wide selection and find something you like”
                  </p>
                </>
              ) : (
                <p className="text-brand">Your cart is empty!</p>
              )}
            </div>
            <LazyLoad height={200}>
              <img
                src={empty}
                alt="empty-pic"
                className="h-100"
                height="400"
                width="400"
                title="cart-empty"
                loading="eager"
              />
            </LazyLoad>
          </div>
          <Link to="/products" className="m-auto w-fit">
            <span className="btn btn-xs submit-auto-width font-smaller hover-up mt-30">
              <i className="fi-rs-home mr-5"></i> Back To Shopping
            </span>
          </Link>
        </>
      ) : (
        <>
          <div
            className="d-flex align-items-center flex-revers-col emptyCart"
            style={style}
          >
            <LazyLoad height={200}>
              <img
                src={empty}
                alt="empty-pic"
                className="h-100"
                height="400"
                width="400"
                title="cart-empty"
                loading="eager"
              />
            </LazyLoad>
            <div>
              {style?.isDes ? (
                <>
                  <h4 className="text-brand cartempty">عربة التسوق فارغة!</h4>
                  <p className="text-black cartemptySub">
                    “استكشف مجموعتنا الواسعة واعثر على ما يعجبك”
                  </p>
                </>
              ) : (
                <p className="text-brand">عربة التسوق فارغة!</p>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Link to="/products" className="w-fit">
              <span className="btn btn-xs submit-auto-width font-smaller hover-up mt-30">
                العودة إلى التسوق
                <i className="fi-rs-home mr-5 ml-5"></i>
              </span>
            </Link>
          </div>
        </>
      )}
    </>
  )
}

export default EmptyCartSvg

EmptyCartSvg.propTypes = {
  style: PropTypes.any,
}
